import '@/styles/globals.css';

import { Button, ConfigProvider, Modal } from 'antd';
import ja_JP from 'antd/lib/locale/ja_JP';
import dayjs from 'dayjs';
import { useAtom } from 'jotai';
import { memoize } from 'lodash';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { scan } from 'react-scan';
import { useErrorBoundary, withErrorBoundary } from 'react-use-error-boundary';

import { theme } from '@/common/theme';
import ErrorComponent from '@/components/Error';
import { planState } from '@/jotai/atoms/authState';
import NotificationProvider from '@/providers/NotificationProvider';
import { configureClientAmplify } from '@/utils/amplify/amplifyClientUtils';
import sendSentry from '@/utils/sentry';

if (typeof window !== 'undefined' && process.env.NODE_ENV === 'development') {
  scan({
    enabled: true,
    log: false,
  });
}

configureClientAmplify();

const App = withErrorBoundary(({ Component, pageProps }: AppProps) => {
  const [error] = useErrorBoundary(async error => {
    sendSentry(error);
  });
  const router = useRouter();
  const plan = useAtom(planState)[0];
  const mutedConsole = memoize(console => ({
    ...console,
    warn: (...args: string[]) =>
      args[0].includes('Duplicate atom key') ? null : console.warn(...args),
  }));
  global.console = mutedConsole(global.console);

  if (error) {
    return <ErrorComponent />;
  }
  return (
    <ConfigProvider theme={theme} locale={ja_JP}>
      <NotificationProvider>
        {plan && (
          <Modal
            title='プランの期限が切れました'
            width={600}
            centered={true}
            className='-translate-y-14'
            open={
              dayjs(plan.expiredDate).diff(dayjs(), 'day') <= -1 &&
              !(
                router.pathname.startsWith('/admin/billings') ||
                router.pathname.startsWith('/auth/')
              )
            }
            maskStyle={{
              backdropFilter: 'blur(10px)',
              backgroundColor: 'rgba(0, 0, 0, 0.4)',
            }}
            closable={false}
            footer={
              <Button
                type='primary'
                onClick={() => router.push('/admin/billings/intro')}
              >
                更新する
              </Button>
            }
          >
            <div className=''>
              <div className='my-6'>
                引き続き利用する方はプランを更新してください
              </div>
            </div>
          </Modal>
        )}
        <Component {...pageProps} />
      </NotificationProvider>
    </ConfigProvider>
  );
});

export default App;
