const ErrorComponent = () => {
  return (
    <>
      <div className='mt-[35vh] flex  w-screen items-center justify-center'>
        <h1 className='text-4xl'>アプリケーションで問題が発生しました</h1>
      </div>
      <div className='flex w-screen items-center justify-center'>
        <button
          type='button'
          className='h-[80px] w-[200px] rounded-xl border-none bg-accent text-xl text-white shadow-md hover:bg-accent-hover'
          onClick={() => window.location.reload()}
        >
          再読み込み
        </button>
      </div>
    </>
  );
};

export default ErrorComponent;
