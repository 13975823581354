import type { ResourcesConfig } from 'aws-amplify';

export const config: ResourcesConfig['Auth'] = {
  Cognito: {
    identityPoolId: process.env.NEXT_PUBLIC_COGNITO_IDENTITY_POOL_ID ?? '',
    userPoolClientId:
      process.env.NEXT_PUBLIC_COGNITO_USER_POOL_WEBCLIENT_ID ?? '',
    userPoolEndpoint:
      process.env.NEXT_PUBLIC_IS_BACKEND_LOCAL === 'true'
        ? 'http://localhost:9229'
        : undefined,
    userPoolId: process.env.NEXT_PUBLIC_COGNITO_USER_POOL_ID ?? '',
  },
};
